import React, { useEffect, useState } from 'react';
import SEO from "../seo"
import { Link } from "gatsby"
import firebase from "gatsby-plugin-firebase"

import "../../styles/global.scss"
import "./LandingPage.scss"
import HeroSignUpForm from "../HeroSignUpForm/HeroSignUpForm"
import Footer from '../Footer';

const LandingPage = (props) => {

  const {
    pageType,
    seo,
    image,
    headerButtonLabel, 
    headerButtonLink,
    heroTitle, 
    heroCtaLabel, 
    heroImg,
    SectionOneImg,
    SectionOneTitle,
    SectionOneBody,
    SectionTwoImg,
    SectionTwoTitle,
    SectionTwoBody,
    endTitle,
    endCtaLabel
  } = props;

  const [screenWidth, setScreenWidth] = useState('');

  useEffect(() => {
    firebase
    .auth()
    .signInAnonymously()
    .catch(function(error) {
      // Handle Errors here.
      //var errorCode = error.code;
      //var errorMessage = error.message;
      // ...
    });
    firebase
    .auth()
    .onAuthStateChanged(function(user) {
      if (user) {
        // User is signed in.
        //var isAnonymous = user.isAnonymous;
        // ...
      } else {
        // User is signed out.
        // ...
      }
      // ...
    });
    setScreenWidth(window.innerWidth)
    window.addEventListener('resize', handleResize);
  }, []);

  const handleResize = () => {
    setScreenWidth(window.innerWidth)
  };

  return (
    <div>
      <SEO title={seo} description={heroTitle} image={image}/>
      <div className="container d-flex justify-content-between py-3 mb-5">
      <Link to="/"><img alt="HiStartup Logo" src={require('../../images/Primary.svg')}></img></Link>
        <div>
          <Link to={headerButtonLink} className="btn btn-primary-light">{headerButtonLabel}</Link>
        </div>
      </div>
      <br></br>
      <div className="container mt-0 mt-md-4 mb-5">
        {screenWidth > 767 ? 
          <h1 className="hero-title text-center mx-auto mb-5">{heroTitle}</h1> 
          : 
          <h2 className="hero-title text-center mx-auto mb-5">{heroTitle}</h2>
        }
        <div className="d-flex justify-content-center mb-4">
          <HeroSignUpForm label={heroCtaLabel} pageType={pageType} position="top" />
        </div>
        <br></br>
        <div className="app-preview-startup-wrapper mt-2 mt-md-5 mb-5 mx-auto">
          <div className={`app-preview-startup-background ${pageType}`}>
            <img alt={heroTitle} src={heroImg} className="app-preview-startup"></img>
          </div>
        </div>
      </div>
      <br></br>
      <br></br>
      <div className="container d-flex mt-5 mb-5">
        <div class="row align-items-center justify-content-between w-100">
          <div className={`col-12 col-md-6 mt-5 mt-md-0 mx-auto ${screenWidth > 767 ? 'order-1' : 'order-2'}`} style={{maxWidth:'480px'}}>
            <h3>{SectionOneTitle}</h3>
            <p>{SectionOneBody}</p>
          </div>
          <div className={`col-12 col-md-6 text-center mx-auto ${screenWidth > 767 ? 'order-2' : 'order-1'}`}>
            <img alt={SectionOneTitle} src={SectionOneImg} style={{width:'100%', maxWidth:'440px'}}></img>
          </div>
        </div>
      </div>
      <br></br>
      <br></br>
      <div className="container d-flex mt-5 mb-5">
        <div class="row align-items-center justify-content-between w-100">
          <div className="col-12 col-md-6 text-center text-md-left order-1 mx-auto">
            <img alt={SectionTwoTitle} src={SectionTwoImg} style={{width:'100%', maxWidth:'440px'}}></img>
          </div>
          <div className="col-12 col-md-6 order-2 mt-5 mt-md-0 mx-auto" style={{maxWidth:'480px'}}>
            <h3>{SectionTwoTitle}</h3>
            <p>{SectionTwoBody}</p>
          </div>
        </div>
      </div>
      <br></br>
      <br></br>
      <div className="end-cta-section my-5">
        <div className="container py-5">
          {screenWidth > 767 ? 
            <h1 style={{maxWidth:"720px"}} className="text-white my-5">{endTitle}</h1> 
            : 
            <h2 style={{maxWidth:"720px"}} className="text-white my-5">{endTitle}</h2>
          }
          <div className="mb-5">
            <HeroSignUpForm label={endCtaLabel} pageType={pageType} position="end" />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default LandingPage;

