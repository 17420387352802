import React, { useState } from "react";
import './HeroSignUpForm.scss';

import firebase from "gatsby-plugin-firebase"
import { Modal } from "react-bootstrap";

const HeroSignUpForm = (props) => {
  const { label, pageType, position } = props;

  const [email, setEmail] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onChange = (e) => {
    setEmail(e.target.value);
    setErrorMsg('');
  }

  const onSubmit = (evt) => {
    evt.preventDefault()
    if (email) {
      setLoading(true);
      firebase
        .firestore()
        .collection("users")
        .add({
          email: email,
          userType: pageType,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then(function (docRef) {
          setEmail('')
          setLoading(false);
          handleShow();
          typeof window !== "undefined" && window.gtag("event", "submit", {pageType})
          console.log("Document written with ID: ", docRef.id);
        })
        .catch(function (error) {
          setLoading(false);
          setErrorMsg("Error adding document")
        });
    } else {
      setErrorMsg(`Please enter an email address`)
    }
  }

  return (
    <>
      <div className="w-100">
        <div className={`hero-form ${position} w-100 w-sm-auto`}>
          <form onSubmit={onSubmit} className={`d-flex flex-column flex-sm-row ${position === 'top' ? 'justify-content-center' : ''}`}>
            <input
              type="email"
              placeholder="Email address"
              value={email}
              onChange={onChange}
              className={position === 'top' ? 'top' : 'end'}
            />
            <input type="submit" value={loading === true ? 'Creating...' : label} className={`btn ${position === 'top' ? 'btn-primary' : 'btn-dark'} ${loading === true ? 'btn-disabled-high' : ''}`} />
          </form>
        </div>
        {errorMsg !== '' ? <p className={`${position === 'top' ? 'text-center error ' : 'text-left text-white'} mt-3 mb-0`}>{errorMsg}</p> : null}
      </div>
      <Modal show={show} onHide={handleClose} centered>
        <div className="p-4 d-flex flex-column">
          <h3>We're almost ready</h3>
          <p>Thank you so much for your interested in HiStartup. We're still working on putting the finishing touches on the product right now.</p>
          <p>You're in the queue for early access and you'll be the first to know when HiStartup is ready.</p>
          <p>See you soon,</p>
          <p className="mb-3 text-high text-italic">The HiStartup Team</p>
          <img className="mr-auto mb-5" alt="HiStartup Logo" src={require('../../images/Primary.svg')}></img>
          <button className="btn btn-primary-light" onClick={handleClose}>Close</button>
        </div>
      </Modal>
    </>
  )
}

export default HeroSignUpForm;
